import { Box,Divider,Typography } from '@mui/material'
import React from 'react'
import {styled} from '@mui/system'
import Header from '../headers/headerwhite'
import Footer from '../Footer/footer'
import CEO from '../assets/images/CEO.png'
import CTO from '../assets/images/CTO.png'

function Contact() {

    const Typo = styled(Typography)`
    color:black;
    text-transform:capitalize;
    opacity:.6;
    text-shadow: 2px 0 white, -2px 0 white, 0 2px white, 0 -2px white,
    1px 1px white, -1px -1px white, 1px -1px white, -1px 1px white;
    
    `
    const Close = styled(Typography)`
    color:#FFFFFF;
    text-transform:capitalize;
    opacity:1;
    text-shadow: 2px 0 white, -2px 0 white, 0 2px white, 0 -2px white,
    1px 1px white, -1px -1px white, 1px -1px white, -1px 1px white;
    
    `
    document.body.style.backgroundColor ="black"
  return (
    <Box>
        <Header/>
        
        <Typo sx={{fontSize:"5.3rem",fontWeight:800,lineHeight:"5.1rem",textAlign:"center",mt:12,fontFamily:"Montserrat"}}>Meet the Duo</Typo>
        <Box sx={{width:"80%",mx:"auto",display:"flex",justifyContent:"space-between",alignItems:"center",mt:12}}>
          {/* ---------------------------Text------------------------ */}
          <Box sx={{color:"white",width:"60%"}}>
              <Typography component="p" sx={{fontSize:"32px",fontWeight:600,fontFamily:"Montserrat"}}>Mohammed Ajnash, <Typography component="span" sx={{display:"block",fontSize:"15px",fontFamily:"Montserrat"}}>CEO of Vappas Adetive Technology</Typography></Typography>
              <Typography component="p" sx={{mt:3,fontFamily:"Montserrat"}}>Entrepreneurship in Oxford. Creative technologists use innovative technology to provide
               business solutions. Tech partner for educational institutes, food chains, warehouse businesses, etc.</Typography>
          </Box>
          {/* -------------------------------Profile------------------------ */}
          <Box component={"img"} src={CEO} sx={{width:"25%",borderRadius:3}} />
        </Box>

        {/* ----------------------------Second---------------------------------- */}
        <Box sx={{width:"80%",mx:"auto",display:"flex",justifyContent:"space-between",alignItems:"center",mt:8}}>
          {/* ---------------------------Profile------------------------ */}
          <Box component={"img"} src={CTO} sx={{width:"25%",borderRadius:3}} />
          {/* -------------------------------Text------------------------ */}
          <Box sx={{color:"white",width:"60%"}}>
              <Typography component="p" sx={{fontSize:"32px",fontWeight:600,fontFamily:"Montserrat"}}>Nafiz Ahmed <Typography component="span" sx={{display:"block",fontSize:"15px",fontFamily:"Montserrat"}}>CTO of Vappas Adetive Technology</Typography></Typography>
              <Typography component="p" sx={{mt:3,fontFamily:"Montserrat"}}>Years and years of expertise developing sophisticated solutions for Tasheel, Tadbeer, Tawjeeh, and other systems. focused on offering business owners comprehensive IT assistance to lessen their burden.</Typography>
          </Box>
    </Box>
    
        <Typo sx={{width:"95%",mx:"auto",fontSize:"5rem",fontWeight:800,lineHeight:"5.1rem",textAlign:"center",mt:12,fontFamily:"Montserrat"}}>I KNOW WORDS I HAVE THE BEST WORDS</Typo>
        
        <Box sx={{color:"white",display:"flex",justifyContent:"space-between",width:"90%",mx:"auto",mt:10}}>
        <Box sx={{width:"40%"}}>
            <Typography component="p" sx={{fontSize:"26px",fontWeight:"600",fontFamily:"Montserrat",textTransform: 'uppercase',}}>Hard Work</Typography>
            <Typography component="p" sx={{mt:2}}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint beatae soluta, 
            facilis pariatur assumenda veniam sunt illum libero. </Typography>
        </Box>

        <Box sx={{width:"40%"}}>
        <Typography component="p" sx={{fontSize:"26px",fontWeight:"600",fontFamily:"Montserrat",textTransform: "uppercase",}}>Healthy Relationships</Typography>
            <Typography component="p" sx={{mt:2}}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint beatae soluta, 
            facilis pariatur assumenda veniam sunt illum libero. </Typography>
        </Box>


        </Box>
        <Box sx={{color:"white",display:"flex",justifyContent:"space-between",width:"90%",mx:"auto",mt:4}}>
        <Box sx={{width:"40%"}}>
            <Typography component="p" sx={{fontSize:"26px",fontWeight:"600",fontFamily:"Montserrat",textTransform: 'uppercase',}}>ORGINALITY</Typography>
            <Typography component="p" sx={{mt:2}}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint beatae soluta, 
            facilis pariatur assumenda veniam sunt illum libero. </Typography>
        </Box>

        <Box sx={{width:"40%"}}>
        <Typography component="p" sx={{fontSize:"26px",fontWeight:"600",fontFamily:"Montserrat",textTransform: "uppercase",}}>GOOD TIMES</Typography>
            <Typography component="p" sx={{mt:2}}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint beatae soluta, 
            facilis pariatur assumenda veniam sunt illum libero. </Typography>
        </Box>


        </Box>
        <Box sx={{color:"white",display:"flex",justifyContent:"space-between",width:"90%",mx:"auto",mt:4}}>
        <Box sx={{width:"40%"}}>
            <Typography component="p" sx={{fontSize:"26px",fontWeight:"600",fontFamily:"Montserrat",textTransform: 'uppercase',}}>AUTHENTICITY</Typography>
            <Typography component="p" sx={{mt:2}}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint beatae soluta, 
            facilis pariatur assumenda veniam sunt illum libero. </Typography>
        </Box>

        <Box sx={{width:"40%"}}>
        <Typography component="p" sx={{fontSize:"26px",fontWeight:"600",fontFamily:"Montserrat",textTransform: "uppercase",}}>QUINTESSENTIALS “ISM”</Typography>
            <Typography component="p" sx={{mt:2}}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint beatae soluta, 
            facilis pariatur assumenda veniam sunt illum libero. </Typography>
        </Box>


        </Box>
        <Close sx={{width:"75%",mx:"auto",mb:10,fontSize:"5rem",fontWeight:800,lineHeight:"5.1rem",textAlign:"center",mt:12,fontFamily:"Montserrat"}}>99% OF MY CLIENTS SAY I’M A GENIUS</Close>
        <Footer/>
    </Box>
    
  )
}

export default Contact