import React from 'react'
import Box from '@mui/material/Box'
import Profile from '../assets/images/Profile.png'
import Profile_button from '../assets/images/profile_button.png'
import Flag from '../assets/images/Flag.png'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import Rating from '@mui/material/Rating';
import MessageIcon from '@mui/icons-material/Message';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import Similar from '../assets/images/Similar People.png'
import Tabs from './tabs'
// import Table from '../Home/tables'
// import Personaltable from '../Home/tablesPersonal'
// import Passporttable from '../Home/tablesPassport'
// import Experiencetable from '../Home/tablesExperience'
// import Languagetable from '../Home/tableLanguage'



function Home() {
    const [value, setValue] = React.useState(5);
    document.body.style.backgroundColor ="white"
    




  return (
   <Box sx={{backgroundColor:"white"}}>
    <Box sx={{width:"100%",mx:"auto",py:12}}>
        <Grid container>
            <Grid item xs={3} md={2} sx={{textAlign:"center"}}>
                <KeyboardBackspaceIcon sx={{fontSize:"35px",color:"black"}}/>
            </Grid>
            {/* <Grid item xs={3} md={4} sx={{textAlign:"center"}}>
                <Box sx={{zIndex:0,position:"relative"}}>
                <Box component="img" src={Profile} sx={{width:{xs:"28%",md:"70%"}}}/>
                <Box component="img" src={Profile_button} sx={{width:{xs:"8%",md:"18%"},top:"36%",left:{xs:"46%",md:"42%"},zIndex:1,position:"absolute"}}/>
                </Box>
            </Grid> */}
        
        {/*  */}
        <Grid item xs={6} md={7}>
            <Grid container>
    
                <Grid item xs={12} md={6}>
            <Box sx={{zIndex:0,position:"relative"}}>
                <Box component="img" src={Profile} sx={{width:{xs:"55%",md:"70%"},marginLeft:{xs:"19%",md:"none"}}}/>
                <Box component="img" src={Profile_button} sx={{width:{xs:"18%",md:"19%"},top:{xs:"33%",md:"37%"},left:{xs:"37%",md:"46%"},zIndex:1,position:"absolute"}}/>
                </Box>
                </Grid>
                <Grid item xs={12} md={6}>
            <Box sx={{}}>
            <Typography component="p" sx={{display:"flex",flexDirection:"row",alignItems:"center",fontSize:{xs:"21px",md:"28px"},fontWeight:"550"}}>Juliana <Typography component="img" src={Flag} sx={{width:"7%",mx:1}}></Typography> Uganda</Typography>
            <Typography component="p" sx={{fontSize:{xs:"18px",md:"28px"},fontWeight:"500",py:1}}>5 years Experience</Typography>
            <Box sx={{
            '& > legend': { mt: 2 },
            fontWeight:"900",display:"flex",alignItems:"center",py:1
            }}>
            <Rating name="read-only" sx={{color:"red"}} value ={value} readOnly />
            <Typography component="p" sx={{fontSize:{xs:"17px",md:"22px"},fontWeight:"500",mx:1}}>(4.8)</Typography>   

            </Box>

            <Typography component="p" sx={{color:"blue",fontSize:{xs:"21px",md:"28px"},fontWeight:"550",py:1}}>AED 90.00 / hr</Typography>
            <Box sx={{display:"flex",py:1}}>
 
            <Typography component="p" sx={{fontSize:{xs:"14px",md:"20px"},backgroundColor:"blue",color:"white",border:2,width:{xs:"100%",md:"45%"},display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"50px 50px 50px  50px",fontWeight:600}}><MessageIcon sx={{fontSize:"1rem"}}/> Message</Typography>
  
            <Typography component="p" sx={{fontSize:{xs:"14px",md:"20px"},backgoroundColor:"white",color:"blue",border:2,width:{xs:"100%",md:"45%"},py:{xs:1,md:2},textAlign:"center",borderRadius:"50px 50px 50px  50px",fontWeight:600}}>Book Now</Typography>
            </Box>
            </Box>
            </Grid>
            </Grid>
        </Grid>
        <Grid item xs={3} md={3} sx={{display:"flex",flexDirection:"column",alignItems:"center"}}>
        <FavoriteBorderIcon sx={{fontSize:{xs:"34px",md:"40px"},color:"#a1005e"}}/>
        <ShortcutIcon sx={{fontSize:{xs:"34px",md:"40px"},color:"grey",mt:4}}/>
        </Grid>
    </Grid>
    </Box>
    {/* --------------------------------------Profile---------------------------------------- */}
    <Box sx={{width:{xs:"100%",md:"80%"},mx:"auto"}}>
        <Grid container>
            <Grid item xs={4} md={4} sx={{textAlign:"center"}}>
                <Typography componen="p" sx={{fontSize:{xs:"1.5rem",md:"1.5rem"},fontWeight:500}}>100+</Typography>
                <Typography componen="p" sx={{fontSize:{xs:"1.5rem",md:"1.5rem"}}}>Reviews</Typography>
            </Grid>
            <Grid item xs={4} md={4} sx={{textAlign:"center"}}>
                <Typography componen="p" sx={{fontSize:{xs:"1.5rem",md:"1.5rem"},fontWeight:500}}>5+</Typography>
                <Typography componen="p" sx={{fontSize:{xs:"1.5rem",md:"1.5rem"}}}>Ongoing</Typography>
            </Grid>
            <Grid item xs={4} md={4} sx={{textAlign:"center"}}>
                <Typography componen="p" sx={{fontSize:{xs:"1.5rem",md:"1.5rem"},fontWeight:500}}>60+</Typography>
                <Typography componen="p" sx={{fontSize:{xs:"1.5rem",md:"1.5rem"}}}>Works did</Typography>
            </Grid>
        </Grid>
    </Box>

   

    

    {/* ---------------------Services/Activities------------------------------ */}
    <Tabs/>
    {/* <Table/> */}
    {/* <Personaltable/> */}
    {/* <Passporttable/> */}
    {/* <Experiencetable/> */}
    {/* <Languagetable/> */}

    {/* ----------------------------------------------Similar People----------------------------- */}
    <Typography component="p" sx={{fontSize:{xs:"20px",md:"30px"},fontWeight:600,width:"48%",textAlign:"center"}}>Similar People</Typography>
    <Box sx={{display:"flex",justifyContent:"right",mb:10,mt:1}}>


    <Grid container >
        {[0,1,2].map((item,index)=>{
            return <Grid item xs={12} md={6} key={index}>
<Box sx={{border:2,py:2,mx:2,borderRadius:3,borderColor:"#0000000D",mb:2}}>
            <Box sx={{mx:2}}>
                <Grid container>
                <Grid item xs={4} md={4}>
                        <Box sx={{position:"relative"}}>
                        <Box component="img" src={Similar} sx={{width:"80%"}}/>
                        <Box component="img" src={Profile_button} sx={{width:"14%",top:"76%",left:"2%",zIndex:1,position:"absolute"}}/>
                        </Box>
                    </Grid>
            <Grid item xs={6} md={6}>
            <Box>
                <Typography componen="p" sx={{fontSize:"18px",border:1,width:"150px",color:"blue",backgroundColor:"#DFE1EF80",borderColor:"#DFE1EF80",borderRadius:1}}>Juliana</Typography>
                <Typography component="p" sx={{display:"flex",flexDirection:"row",alignItems:"center",fontSize:{xs:"13px",md:"18px"}}}>Nationality :  <Typography component="img" src={Flag} sx={{width:"7%",mx:1}}></Typography> Uganda</Typography>
                <Typography componen="p" sx={{fontWeight:600,fontSize:{xs:"17px",md:"20px"}}}>Housemaid</Typography>
                <Typography componen="p" sx={{color:"blue",fontSize:"20px",fontWeight:"600"}}>Salary: 1300</Typography>
                <Typography componen="p" sx={{fontSize:{xs:"15px",md:"17px"},display:"flex",flexDirection:"row",alignItems:"center"}}>4.8 <Typography component="p" sx={{fontSize:{xs:"0.6rem",md:"1rem"},mx:1,color:"#737373E5"}}>| 4 Years of Experience</Typography></Typography>

            </Box>
            </Grid>
            <Grid item xs={2} md={2} >
                <Box sx={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                    <FavoriteBorderIcon sx={{fontSize:{xs:"28px",md:"40px"},color:"#a1005e"}}/>
                    <ShortcutIcon sx={{fontSize:{xs:"28px",md:"40px"},color:"grey",mt:4}}/>
                 </Box>
            </Grid>
        </Grid>
        </Box>
    </Box>
            </Grid>
        })}
    
    </Grid>

    </Box>

   </Box>
  )
}

export default Home