import React,{Suspense, lazy} from 'react'
import {Box,Typography,Grid,Container} from "@mui/material"
import Intropic from '../assets/images/desert-landscape-background-video-conferencing_23-2148652924 1.png'
import './intro.css'
import Header from '../headers/header'
import India from '../assets/images/Property 1=Frame India.png'
import Dubai from '../assets/images/Property 1=Frame Dubai.png'
import Qatar from '../assets/images/Property 1=Frame Qatar.png'
const VappasText = lazy(()=>import('./components/VappasText')) 
const CaseStudies = lazy(()=>import('./components/CaseStudies')) 
const Footer = lazy(()=>import('../Footer/footer')) 
const Feedback = lazy(()=>import('./components/feedback')) 
function Intro() {
  
  const imageStyle = {
    width:"100%",
    height:{xs:"80%",md:"auto"},
    objectFit:"contain"
  }

  const pages = [
    <VappasText/>,
    <CaseStudies/>,
    
    <Feedback/>,<Footer/>]
    document.body.style.backgroundColor ="black"
  return (
    <>
    <Box sx={{position:"relative  "}}>
<Box sx={{position:"absolute",zIndex:"4",width:"100%",top:"20px"}}>
<Header/>
</Box>
        <Box component="img"  loading="lazy" src={Intropic} className='intro' sx={{height:"100%",width:"100%",zIndex:0}} />
        <Box sx={{position: 'absolute',zIndex:1,width:"70%",mx:"auto",fontSize:{sm:"100px",md:"30px",lg:"28px",xl:"10px"}}} className="texty"  >
        <Box component="p"  sx={{fontWeight:"600",mt:{xs:2,md:0},textAlign:"center",lineHeight:{xs:"1rem",md:"3.1rem"},fontSize:{xs:"12px",sm:"20px",md:"30px",lg:"28px",xl:"10px"}}} >Value Added IT Solutions For All UAE Digital Problems</Box>
        <Box component="p" sx={{fontWeight:"300",mt:{xs:0,md:0},mt:{md:"-15px"},lineHeight:{xs:".8rem",md:"1.4rem"},fontSize:{xs:"12px",md:"20px"},textAlign:"center",width:{xs:"90%",md:"75%"},mx:"auto",}} >Every organisation faces Unique issues, thus we offer Unique solutions using technology.</Box>
        </Box>
</Box>
    <Typography component="p" sx={{color:"white",textAlign:"center",fontWeight:600,fontSize:{xs:"22px",sm:"28px",lg:"31px"},my:{xs:2,md:5}}} > We served in <Typography component={"span"} sx={{color:"red",fontWeight:600,fontSize:{xs:"22px",sm:"23px",lg:"31px"}}}>4</Typography>  Countries </Typography>
  <Box >
  <Grid container sx={{width:"95%",mx:"auto"}}>
    <Grid item xs={12} md={3} sx={{mt:{xs:1,md:3} }}>
    <Box component="img" sx={imageStyle} src={India}/>
    </Grid>
     <Grid item xs={12} md={3}sx={{mt:{xs:-5,md:3}}}>
    <Box component="img" sx={imageStyle} src={Dubai}/>
    </Grid>
    <Grid item xs={12} md={3}sx={{mt:{xs:-5,md:3}}}>
    <Box component="img" sx={imageStyle} src={Qatar}/>
    </Grid>
    <Grid item xs={12} md={3}sx={{mt:{xs:-5,md:3}}}>
    <Box component="img" sx={imageStyle} src={Dubai}/>
    </Grid>
  </Grid> 
  </Box>

  {pages.map(page => <Suspense fallback={<div>Loading...</div>} >
  {page}
  </Suspense>)}

    </>

  )
}

export default Intro