import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Logo from '../assets/images/Logo.png';
import {useNavigate} from 'react-router-dom'


import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/Inbox';
import VectorIcon from '../assets/images/Vector.png';
import MailIcon from '@mui/icons-material/Mail';

function DrawerAppBar() {
  
const navigate = useNavigate()
const style={
fontWeight: '600',
fontSize:"12px",
capitalize: 'bold',
textTransform: 'uppercase',
letterSpacing:"1px",
cursor:"pointer",
display: {xs:"none",md:"inline-block"}
}


const [state, setState] = React.useState({
  right: false,
});




const toggleDrawer = (anchor, open) => (event) => {
  if (
    event &&
    event.type === 'keydown' &&
    (event.key === 'Tab' || event.key === 'Shift')
  ) {
    return;
  }

  setState({ ...state, [anchor]: open });
};

const list = (anchor) => (
  <Box
    sx={{ width: 220,position: 'relative',zIndex:10}}
    role="presentation"
    onClick={toggleDrawer(anchor, false)}
    onKeyDown={toggleDrawer(anchor, false)}
  >
    <List>
      {['Industries', 'Service', 'About us', 'Contact'].map((text, index) => (
        <ListItem key={text} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItemButton> 
        </ListItem>
      ))}
    </List>
    <Divider />
  </Box>
);



  return (<>
  
  <SwipeableDrawer sx={{position:"absolute",zIndex:10}} 
            anchor={"right"}
              open={state["right"]}
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("right", true)}
          >
            {list("right")}
          </SwipeableDrawer>




    <Box sx={{mt:1,display:"flex",justifyContent:{xs:"space-between",md:"space-around"},alignItems:"center",px:{xs:"10px",md:"0px"}}}>
  
         <Typography sx={style} onClick={()=>navigate("/industries")}>Industries</Typography>
         <Typography sx={style} onClick={()=>navigate("/service")}>service</Typography>
         <Box component={"img"} onClick={()=>navigate("/intro")} sx={{width:{xs:"10%",md:"5%"},display:"inline"}} src={Logo}/>
         <Typography sx={style} onClick={()=>navigate("/about")}>About us</Typography>
         <Typography sx={style} onClick={()=>navigate("/contact")}>contact</Typography>
         <Box  component="img" edge="start" onClick={toggleDrawer("right", true)} aria-label="open drawer" src={VectorIcon} sx={{width:"20px",display:{md:"none"}}} />
         
         
        
          
          
   
   </Box>
    </>
  );
}



export default DrawerAppBar;
