import React from 'react'
import {Box,Typography,Grid,Container, List} from "@mui/material"

import Service from '../assets/images/Service.png'
import Digital from '../assets/images/Digital Transformationcopy.png'
import Web from '../assets/images/Web & Mobcopy.png'
import Uiux from '../assets/images/Ade UIUXcopy.png'
import Creativeads from '../assets/images/Creative Adscopy.png'

import Footer from '../Footer/footer'

function Intro() {
  
  const imageStyle = {
    width:"100%"
  }
  document.body.style.backgroundColor ="black"
  return (
    <>
    <Box sx={{position:"relative"}}>
<Box sx={{position:"absolute",zIndex:"4",width:"100%",top:"20px"}}>

</Box>
        <Box component="img" src={Service} className='intro' sx={{height:"100%",width:"100%",zIndex:0}} />
        <Box sx={{position: 'absolute',zIndex:1,width:"47%",mx:"auto"}} className="texty"  >
        <Box component="p" sx={{fontWeight:"600",fontSize:{sm:"40px",md:"30px",lg:"50px",xl:"10px"},textAlign:"center",color:"white",lineHeight:"1.2em"}} >Find the service that matches your goals</Box>
        <Box sx={{border:1,borderColor:"white",width:"40%",mx:"auto",py:2,textAlign:"center",mt:5,fontSize:"12px",borderRadius:"160px",color:"white"}}> SCHEDULE A CALL</Box>
</Box>
        
</Box>
<Box sx={{width:"65%",py:5,backgroundColor:"white",display:"flex",justifyContent:"space-between",fontFamily:"Montserrat"}}>
    <Typography component="p" sx={{fontSize:"16px",mx:2}}> DIGITAL TRANSFORMATION  </Typography>
    <Typography component="p" sx={{fontSize:"16px"}}>Customised WEB & MOBILE APPS </Typography>
    <Typography component="p" sx={{fontSize:"16px"}}> AdeUIUX </Typography>
    <Typography component="p" sx={{fontSize:"16px",mx:2}}> Creative ADS </Typography>
    </Box>
    {/* ------------------------------Digital Transformation---------------------------- */}
    <Box sx={{width:"85%",mx:"auto",mt:15,fontFamily:"Montserrat"}}>
      <Box component="img" src={Digital} sx={{width:"100%"}} />
      <Typography component="p" sx={{mt:4,color:"white",fontSize:"21px",width:"95%",fontWeight:600}}>Empower Your Business with Tailored IT Solutions in UAE - 
      Seamless Digital Transformation from Paper to Digital</Typography>
      <Typography component="p" sx={{color:"#919191",width:"85%",fontSize:"18px",mt:3}}>In the ever-evolving landscape of business, witness a paradigm shift with our custom-made IT solutions in the UAE. Experience the transformative journey from paper to digital, Excel to apps, as we simplify your processes. Our bespoke solutions redefine efficiency, ensuring a seamless digital transformation tailored to address your unique business challenges.
</Typography>
<Typography component="p" sx={{color:"#919191",fontSize:"18px",display:"block",mt:2}}>Personalized Digital Evolution </Typography>
<Typography component="p" sx={{color:"#919191",fontSize:"18px"}}>Streamlined Excel to Apps  </Typography>
<Typography component="p" sx={{color:"#919191",fontSize:"18px"}}>Process Simplification Expertise   </Typography>
<Typography component="p" sx={{color:"#919191",fontSize:"18px"}}>Tailor-Made Solutions for Your Business   </Typography>
      
    </Box>
    {/* ---------------------------------Web & Mobile Apps----------------------------------- */}
    <Box sx={{width:"85%",mx:"auto",mt:15,fontFamily:"Montserrat"}}>
      <Box component="img" src={Web} sx={{width:"100%"}} />
      <Typography component="p" sx={{mt:4,color:"white",fontSize:"21px",width:"95%",fontWeight:600}}>Empower Your Business with Tailored IT Solutions in UAE - 
      Seamless Digital Transformation from Paper to Digital</Typography>
      <Typography component="p" sx={{color:"#919191",width:"85%",fontSize:"18px",mt:3}}>Step into the future of business solutions with our cutting-edge IT services in the UAE. Seamlessly address any business challenge through our web app expertise, employing state-of-the-art technology. We transform your ideas into powerful, user-friendly web and mobile applications, ensuring a dynamic and efficient digital experience for your enterprise.
</Typography>
<Typography component="p" sx={{color:"#919191",fontSize:"18px",display:"block",mt:2}}>Tailored Web Apps for Every Challenge </Typography>
<Typography component="p" sx={{color:"#919191",fontSize:"18px"}}>Cutting-Edge Technology Integration </Typography>
<Typography component="p" sx={{color:"#919191",fontSize:"18px"}}>Seamless Transition to Mobile Apps   </Typography>
<Typography component="p" sx={{color:"#919191",fontSize:"18px"}}>User-Friendly Innovation   </Typography>
      
    </Box>
    {/* ---------------------------------Ade UIUX----------------------------------- */}
    <Box sx={{width:"85%",mx:"auto",mt:15,fontFamily:"Montserrat"}}>
      <Box component="img" src={Uiux} sx={{width:"100%"}} />
      <Typography component="p" sx={{mt:4,color:"white",fontSize:"21px",width:"95%",fontWeight:600}}>Transform Your Vision into Reality with Cost-Efficient IT Solutions in UAE - Unveiling the Power of Working Mockup Models</Typography>
      <Typography component="p" sx={{color:"#919191",width:"85%",fontSize:"18px",mt:3}}>Unlock the potential of your ideas through our innovative IT solutions in the UAE, featuring working mockup models that translate 100% of your vision into practical solutions. This game-changing approach covers just 15% of your development costs, saving you both money and time. With the flexibility to accommodate changes effortlessly, these mockups ensure efficient development, ultimately saving valuable developer time.
</Typography>
<Typography component="p" sx={{color:"#919191",fontSize:"18px",display:"block",mt:2}}>Precision in Visualization </Typography>
<Typography component="p" sx={{color:"#919191",fontSize:"18px"}}>Cost-Effective Development  </Typography>
<Typography component="p" sx={{color:"#919191",fontSize:"18px"}}>Time-Saving Advantage   </Typography>
<Typography component="p" sx={{color:"#919191",fontSize:"18px"}}>Flexibility for Iterative Changes  </Typography>
      
    </Box>
    {/* ---------------------------------Creative Ads----------------------------------- */}
    <Box sx={{width:"85%",mx:"auto",mt:15,fontFamily:"Montserrat"}}>
      <Box component="img" src={Creativeads} sx={{width:"100%"}} />
      <Typography component="p" sx={{mt:4,color:"white",fontSize:"21px",width:"55%",fontWeight:600}}>Creative Thinking - Connecting customers to your brand</Typography>
      <Typography component="p" sx={{color:"#919191",width:"85%",fontSize:"18px",mt:3,mb:10}}>It’s never too late to start, Raise up the competition! We craft Advertisements for Both Static and Dynamic Platforms to Engage Digital Audiences Consistently. The only limit is your imagination, show off the value of your brand. Creative solutions will create the best results In the public eye</Typography>
  </Box>
 
    <Footer/>

    </>

  )
}

export default Intro