import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DoneIcon from '@mui/icons-material/Done';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Country of Exp.',"RIYADH/SAUDI", "1"),
  createData('Baby Sitter', "infant baby", "1"),
  createData('Cooking', <DoneIcon/>, "1"),
  createData('Cleaning', <DoneIcon/>, "1"),
  createData('Washing', <DoneIcon/>, "1"),
];

export default function DenseTable() {
  return (
    <TableContainer component={Paper} sx={{}}>
      <Table sx={{ minWidth: 50 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell sx={{fontSize:"16px",fontWeight:600}}>Years of Exp.</TableCell>
            <TableCell sx={{textAlign:"center"}} align="right">3YRS</TableCell>
            <TableCell sx={{}} align="right">1</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{fontSize:"16px",fontWeight:600}}>
                {row.name}
              </TableCell>
              <TableCell align="right" sx={{textAlign:"center"}}>{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}