import { Box,Typography } from '@mui/material'
import React from 'react'
import {styled} from '@mui/system'
import Header from '../headers/headerwhite'
import Footer from '../Footer/footer'

function Contact() {

    const Typo = styled(Typography)`
    color:black;
    text-transform:capitalize;
    opacity:.6;
    text-shadow: 2px 0 white, -2px 0 white, 0 2px white, 0 -2px white,
    1px 1px white, -1px -1px white, 1px -1px white, -1px 1px white;
    
    `

  return (
    <Box>
        <Header/>
        
        <Typo sx={{textTransform:"uppercase",fontSize:"5.3rem",fontWeight:800,lineHeight:"5.1rem",textAlign:"center",mt:12,fontFamily:"Montserrat"}}>Contact</Typo>
        <Box sx={{color:"white",border:1,borderColor:"white",width:"58%",mx:"auto",py:3,textAlign:"center",mt:5,fontSize:"38px",letterSpacing:"5px",fontWeight:600,borderRadius:"160px"}}>adetive.mail@gmail.com</Box>
        <Box sx={{color:"white",border:1,borderColor:"white",width:"41%",mx:"auto",py:3,textAlign:"center",mt:5,fontSize:"38px",letterSpacing:"5px",fontWeight:600,borderRadius:"160px"}}> +971 56 191 4656</Box>
        
    </Box>
    
  )
}

export default Contact