import { Box, Typography,Divider } from '@mui/material'
import TextField from '@mui/material/TextField';
import React from 'react'

import Logo from '../assets/images/Black_Logo.png'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { useNavigate } from 'react-router-dom';

function Footer() {
    
    const navigate = useNavigate()
  return (
    <Box sx={{backgroundColor:"white",borderRadius:"65px 65px 0px 0px",mb:{xs:10,md:0}}} >
        <Box sx={{textAlign:"center"}}>
            <Box component={"img"} src={Logo} sx={{width:"13%",my:4,textAlign:"center"}}/>
        </Box>
        <Box sx={{}}>
            <Typography component="p" sx={{fontSize:{xs:"22px",md:"58px"},fontFamily:"Montserrat",fontWeight:"800",textAlign:"center"}}>VAPPAS ADETIVE TECHNOLOGY</Typography>
        </Box>
        <Box sx={{width:"80%",mx:"auto",display:"flex",flexDirection:{xs:"column",md:"row"},justifyContent:"space-between",mt:{xs:3,md:5}}}>
            <Box>
                <Typography component="p" onClick={()=>navigate("/industries")} sx={{cursor:"pointer",fontSize:{xs:"10px",md:"16px"},fontWeight:600,fontFamily:"Montserrat"}}>Industries</Typography>
                <Typography component="p" onClick={()=>navigate("/about")} sx={{cursor:"pointer",fontSize:{xs:"10px",md:"16px"},fontWeight:600,fontFamily:"Montserrat",mt:{xs:.5,md:2}}}>About Us</Typography>
                <Typography component="p" onClick={()=>navigate("/service")} sx={{cursor:"pointer",fontSize:{xs:"10px",md:"16px"},fontWeight:600,fontFamily:"Montserrat",mt:{xs:.5,md:2}}}>Service</Typography>
                <Typography component="p" onClick={()=>navigate("/contact")} sx={{cursor:"pointer",fontSize:{xs:"10px",md:"16px"},fontWeight:600,fontFamily:"Montserrat",mt:{xs:.5,md:2}}}>Contact</Typography>
                <Typography component="p" onClick={()=>navigate("/tadbeer")} sx={{cursor:"pointer",fontSize:{xs:"10px",md:"16px"},fontWeight:600,fontFamily:"Montserrat",mt:{xs:.5,md:2}}}>Tadbeer</Typography>
                    <Box sx={{mt:{xs:3,md:5}}}>
                        <FacebookIcon sx={{mr:{xs:2,md:5}}}/>
                        <InstagramIcon sx={{mr:{xs:2,md:5}}}/>
                        <XIcon sx={{mr:{xs:2,md:5}}}/>
                        <LinkedInIcon sx={{mr:{xs:2,md:5}}}/>
                    </Box>
            </Box>
            <Box sx={{mt:5}}>
                <Typography component="p" sx={{fontSize:{xs:"22px",md:"30px"},fontWeight:600,fontFamily:"Montserrat"}  }>NEWSLETTER</Typography>
                <Typography component="p" sx={{fontSize:{xs:"14px",md:"18px"},fontWeight:500,fontFamily:"Montserrat",color:"#000000B0"}}>Subscribe and stay informed on all our latest updates.</Typography>
                <Box sx={{display:"flex",alignItems:"center",mt:{xs:2,md:6}}}>
                <Box
                    sx={{color:"blue"}}
                    component="form"
                    noValidate
                    autoComplete="off">
      
                    <TextField  id="standard-basic" label="Your Email" variant="standard" />
                </Box>
                <ArrowCircleRightIcon sx={{mt:{xs:1,md:0},fontSize:{xs:"30px",md:"40px"}}}/>
                </Box>
                
            </Box>

        </Box>
        <Divider sx={{backgroundColor:"black",mt:5}} />

        <Box sx={{width:"100%",mx:"auto",display:"flex",justifyContent:"center",py:{xs:2,md:5},alignItems:"center"}}>
            <CopyrightIcon sx={{color:"#00000099",fontSize:"14px"}}/>
            <Typography component={"p"} sx={{color:"#00000099",fontFamily:"Montserrat",fontSize:{xs:"12px",md:"14px"}}}>2024 Vappas adetive. All rights reserved.</Typography>
        </Box>
    </Box>
    
  )
}

export default Footer