import { Box,Divider,Typography,Grid } from '@mui/material'
import React from 'react'
import {styled} from '@mui/system'
import Header from '../headers/headerwhite'
import Footer from '../Footer/footer'
import Restaurant from '../assets/images/restaurant.png'
import Education from '../assets/images/education.png'
import Warehousing from '../assets/images/warehousing.png'
import Government from '../assets/images/government centres.png'
import Industries from '../assets/images/Industries.png'
import { List, ListItem, ListItemText } from '@mui/material';
import './industries.css'

function Contact() {

    const imageStyle = {
        width:"95%",
        }

    const Typo = styled(Typography)`  
    color:black;
    text-transform:Uppercase;
    opacity:.8;
    text-shadow: 2px 0 white, -2px 0 white, 0 2px white, 0 -2px white,
    1px 1px white, -1px -1px white, 1px -1px white, -1px 1px white;
    
    `
    const Close = styled(Typography)`
    color:#FFFFFF;
    text-transform:capitalize;
    opacity:1;
    text-shadow: 2px 0 white, -2px 0 white, 0 2px white, 0 -2px white,
    1px 1px white, -1px -1px white, 1px -1px white, -1px 1px white;
    
    `

document.body.style.backgroundColor ="black"

  return (
    <Box>
        <Header/>
        <Box sx={{width:"90%",mx:"auto",textAlign:"center"}}>
            <Box component="img" src={Industries} sx={{width:"85%"}}/>
        </Box>
        
        <Box sx={{width:"90%",mx:"auto"}}>
          
  <Grid container sx={{width:"95%",mx:"auto"}}>
    <Grid item xs={3}>    
  
    <Box component="img" sx={imageStyle} src={Restaurant}/>
    
    </Grid>
     <Grid item xs={3}>
    <Box component="img" sx={imageStyle} src={Education}/>
    </Grid>
    <Grid item xs={3}>
    <Box component="img" sx={imageStyle} src={Warehousing}/>
    </Grid>
    <Grid item xs={3}>
    <Box component="img" sx={imageStyle} src={Government}/>
    </Grid>
  </Grid> 
  </Box>
  <Typo sx={{fontSize:"5.3rem",fontWeight:800,lineHeight:"5.1rem",textAlign:"center",mb:5,mt:12,fontFamily:"Montserrat"}}>Products We Offer :</Typo>
  

   <Box sx={{height:"100vh",overflowX:"hidden",overflowY:"scroll",mb:0}}>
   <Grid container sx={{width:"100%",height:"100%",mx:"auto"}}>
    <Grid item xs={6} sx={{borderBottom:1,borderColor:"#eeeeee"}}>    
    <Box component="p" sx={{my:0,backgroundColor:"white",height:"100%",display:"flex",justifyContent:"center",alignItems:"center",fontSize:"48px",fontWeight:800}}>TTT</Box> 
    </Grid>
    <Grid item xs={6} sx={{py:10}}>  
    <Box sx={{ml:10}}>  
    <Typo sx={{fontSize:"3.3rem"}}>01</Typo> 
    <Box component="p" sx={{color:"white",fontSize:"22px",width:"80%",fontWeight:500}}>Streamlining Business Operations with Cutting-Edge ERP Solutions for Tasheel, Tawjeeh, Tadbeer in UAE</Box> 
    <Box component="p" sx={{color:"white",fontSize:"20px",width:"80%",color:"#FFFFFF"}}>In the fast-paced landscape of UAE's IT solutions, our ERP system stands out for its seamless integration, automating every facet of your business journey. From effortless invoice management to streamlined customer data handling and automatic account updates, our solution ensures a frictionless progress.</Box>
    {/* <Box>
    <List sx={{color:"white"}}>
      <ListItem >
        <ListItemText  sx={{color:"white"}} primary="Item 1" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Item 2" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Item 3" />
      </ListItem>
    </List>
    </Box> */}
    </Box> 
    </Grid>
  </Grid> 
   <Grid container sx={{width:"100%",height:"100%",mx:"auto"}}>
    <Grid item xs={6} sx={{borderBottom:1,borderColor:"#eeeeee"}}>    
    <Box component="p" sx={{my:0,backgroundColor:"white",height:"100%",display:"flex",justifyContent:"center",alignItems:"center",fontSize:"48px",fontWeight:800}}>Wareve</Box> 
    </Grid>
    <Grid item xs={6} sx={{py:10}}>  
    <Box sx={{ml:10}}>  
    <Typo sx={{fontSize:"3.3rem"}}>02</Typo> 
    <Box component="p" sx={{color:"white",fontSize:"22px",width:"80%",fontWeight:500}}>Transforming warehouse operation into Efficiency with Seamless Solutions in the UAE</Box> 
    <Box component="p" sx={{color:"white",fontSize:"20px",width:"80%",color:"#FFFFFF"}}>In the dynamic landscape of IT solutions in the UAE, finding the right ERP is crucial for sustained growth. Our ERP tailored for Typing Centers and emerging businesses effortlessly streamlines invoicing, customer data management, accounts, task tracking, quotations, proposals, and HR processes.</Box>
    {/* <Box>
    <List sx={{color:"white"}}>
      <ListItem >
        <ListItemText  sx={{color:"white"}} primary="Item 1" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Item 2" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Item 3" />
      </ListItem>
    </List>
    </Box> */}
    </Box> 
    </Grid>
  </Grid> 
   <Grid container sx={{width:"100%",height:"100%",mx:"auto"}}>
    <Grid item xs={6} sx={{borderBottom:1,borderColor:"#eeeeee"}}>    
    <Box component="p" sx={{my:0,backgroundColor:"white",height:"100%",display:"flex",justifyContent:"center",alignItems:"center",fontSize:"48px",fontWeight:800}}>Adepos</Box> 
    </Grid>
    <Grid item xs={6} sx={{py:10}}>  
    <Box sx={{ml:10}}>  
    <Typo sx={{fontSize:"3.3rem"}}>03</Typo> 
    <Box component="p" sx={{color:"white",fontSize:"22px",width:"80%",fontWeight:500}}>Elevate Your Restaurant Efficiency with ADEPOS - Streamlined ERP Solutions for UAE Businesses</Box> 
    <Box component="p" sx={{color:"white",fontSize:"20px",width:"80%",color:"#FFFFFF"}}>Unlock seamless business progress with ADEPOS, the ultimate ERP designed to enhance your restaurant's performance. From POS transactions and customer data management to branch oversight and offline/online capabilities, ADEPOS ensures a frictionless experience. With a robust ticketing system and task assignment features, this ERP empowers your staff, making it the go-to solution for restaurant success in the UAE.</Box>
    {/* <Typography component="p" sx={{color:"white",fontSize:"20px",width:"80%",color:"#FFFFFF"}}>Franchise/Branch Management System</Typography>
    <Typography component="p" sx={{color:"white",fontSize:"20px",width:"80%",color:"#FFFFFF"}}>From menu updates to employee scheduling, FMS/BMS ensures seamless coordination, enabling franchise owners to focus on growth and expansion.
</Typography> */}
    {/* <Box>
    <List sx={{color:"white"}}>
      <ListItem >
        <ListItemText  sx={{color:"white"}} primary="Item 1" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Item 2" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Item 3" />
      </ListItem>
    </List>
    </Box> */}
    </Box> 
    </Grid>
  </Grid> 

  <Grid container sx={{width:"100%",height:"100%",mx:"auto"}}>
    <Grid item xs={6} sx={{borderBottom:1,borderColor:"#eeeeee"}}>    
    <Box component="p" sx={{my:0,backgroundColor:"white",height:"100%",display:"flex",justifyContent:"center",alignItems:"center",fontSize:"48px",fontWeight:800}}>ODOO</Box> 
    </Grid>
    <Grid item xs={6} sx={{py:10}}>  
    <Box sx={{ml:10}}>  
    <Typo sx={{fontSize:"3.3rem"}}>04</Typo> 
    <Box component="p" sx={{color:"white",fontSize:"22px",width:"80%",fontWeight:500}}>Revolutionise Your Business with Seamless IT Solutions in UAE and Budget-Friendly ERP in Open Source ODOO
</Box> 
    <Box component="p" sx={{color:"white",fontSize:"20px",width:"80%",color:"#FFFFFF"}}>ODOO emerges as the epitome of efficiency, harmonising diverse modules seamlessly. From managing invoices, customer data, and accounts to handling quotations, proposals, POS, Sales, and Purchase – ODOO transforms complexity into simplicity. Harness the power of IT solutions in the UAE, and elevate your business with budget-friendly ERP that ensures a frictionless journey through every aspect of your enterprise.</Box>
    {/* <Box>
    <List sx={{color:"white"}}>
      <ListItem >
        <ListItemText  sx={{color:"white"}} primary="Item 1" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Item 2" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Item 3" />
      </ListItem>
    </List>
    </Box> */}
    </Box> 
    </Grid>
  </Grid> 
  
   
 
  
   
   </Box>


<Footer/>
    </Box>
    
  )
}

export default Contact