import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home/home'
import Header from './Header/header'
import Footer from './Footer/footer'
import Categories from './Categories/categories'
import Contact from './Contact/contact'

function PrivateRoute({children}) {
  
  
  return (
    <>
    <Header/>
    
{children}
    <Footer/> 
    </>
  );

 
}

export default PrivateRoute;
