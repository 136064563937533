import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import Table from '../Home/tables'
import Personal from '../Home/tablesPersonal'
import Passport from '../Home/tablesPassport'
import Experience from '../Home/tablesExperience'
import Language from '../Home/tableLanguage'
import Shape from '../assets/images/shapes.png'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '75%',mx:"auto",mt:5}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider',display:"flex",justifyContent:"center" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Services" {...a11yProps(0)} sx={{width:"50%",fontSize:{xs:"18px",md:"24px"}}} />
          <Tab label="Reviews" {...a11yProps(1)} sx={{width:"50%",fontSize:{xs:"18px",md:"24px"}}}/>
        
        </Tabs>
      </Box>
      {/* <CustomTabPanel value={value} index={0}>
      <Box>
            <Typography component="p" sx={{fontSize:"24px",fontWeight:600}}>About Juliana</Typography>
            <Typography component="p" sx={{lineHeight:"2rem"}}>Lorem ipsum dolor amit consect Lorem ipsum dolor amit consecteur, Lorem ipsum dolor amit consecteur</Typography>
            <Typography component="p" sx={{color:"blue",display:"flex",fontSize:"16px",lineHeight:"2rem",alignItems:"center"}}><AddIcon sx={{width:"1.5%"}}/> Application Details</Typography>
            <Typography component="p" sx={{color:"blue",display:"flex",fontSize:"16px",lineHeight:"2rem",alignItems:"center"}}><AddIcon sx={{width:"1.5%"}}/>Personal Information</Typography>
            <Typography component="p" sx={{color:"blue",display:"flex",fontSize:"16px",lineHeight:"2rem",alignItems:"center"}}><AddIcon sx={{width:"1.5%"}}/>Passport & VISA</Typography>
            <Typography component="p" sx={{color:"blue",display:"flex",fontSize:"16px",lineHeight:"2rem",alignItems:"center"}}><AddIcon sx={{width:"1.5%"}}/>Work Experience</Typography>
            <Typography component="p" sx={{color:"blue",display:"flex",fontSize:"16px",lineHeight:"2rem",alignItems:"center"}}><AddIcon sx={{width:"1.5%"}}/>Language</Typography>
            <Box sx={{}}>
            <Typography component="p" sx={{fontSize:"20px",fontWeight:600}} >Expertise</Typography>
            <Typography component="p" sx={{lineHeight:"2rem"}}>Cleaning</Typography>
            <Typography component="p" sx={{lineHeight:"2rem"}}>Cooking</Typography>
            <Typography component="p" sx={{lineHeight:"2rem"}}>Laundry</Typography>
            </Box>
            <Box>
            <Typography component="p" sx={{fontSize:"20px",fontWeight:600}} >Location</Typography>
            <Typography component="p" sx={{lineHeight:"2rem"}}>Riyadh,Saudi</Typography>

            </Box>
          </Box>
      </CustomTabPanel> */}
      <CustomTabPanel value={value} index={0}>
      <Box>
      <Typography component="p" sx={{fontSize:"24px",fontWeight:600}}>About Juliana</Typography>
      <Typography component="p" sx={{lineHeight:"2rem"}}>Lorem ipsum dolor amit consect Lorem ipsum dolor amit consecteur, Lorem ipsum dolor amit consecteur</Typography>
      <Box sx={{mt:3}}>
      <Accordion sx={{width:{xs:"100%",md:"60%"}}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{color:"blue"}}
        >
        Application Details
        </AccordionSummary>
        <AccordionDetails>
          <Table/>
        </AccordionDetails>
      </Accordion>
    </Box>
      <Box sx={{mt:3}}>
      <Accordion sx={{width:{xs:"100%",md:"60%"}}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{color:"blue"}}
        >
        Personal Information
        </AccordionSummary>
        <AccordionDetails>
          <Personal/>
        </AccordionDetails>
      </Accordion>
    </Box>
      <Box sx={{mt:3}}>
      <Accordion sx={{width:{xs:"100%",md:"60%"}}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{color:"blue"}}
        >
        Passport & VISA
        </AccordionSummary>
        <AccordionDetails>
          <Passport/>
        </AccordionDetails>
      </Accordion>
    </Box>
      <Box sx={{mt:3}}>
      <Accordion sx={{width:{xs:"100%",md:"60%"}}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{color:"blue"}}
        >
        Work Experience
        </AccordionSummary>
        <AccordionDetails>
          <Experience/>
        </AccordionDetails>
      </Accordion>
    </Box>
      <Box sx={{mt:3}}>
      <Accordion sx={{width:{xs:"100%",md:"60%"}}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{color:"blue"}}
        >
        Language
        </AccordionSummary>
        <AccordionDetails>
          <Language/>
        </AccordionDetails>
      </Accordion>
    </Box>

            
            {/* <Typography component="p" sx={{color:"blue",display:"flex",fontSize:"16px",lineHeight:"2rem",alignItems:"center"}}><AddIcon sx={{width:"1.5%"}}/> Application Details</Typography>
            <Typography component="p" sx={{color:"blue",display:"flex",fontSize:"16px",lineHeight:"2rem",alignItems:"center"}}><AddIcon sx={{width:"1.5%"}}/>Personal Information</Typography>
            <Typography component="p" sx={{color:"blue",display:"flex",fontSize:"16px",lineHeight:"2rem",alignItems:"center"}}><AddIcon sx={{width:"1.5%"}}/>Passport & VISA</Typography>
            <Typography component="p" sx={{color:"blue",display:"flex",fontSize:"16px",lineHeight:"2rem",alignItems:"center"}}><AddIcon sx={{width:"1.5%"}}/>Work Experience</Typography>
            <Typography component="p" sx={{color:"blue",display:"flex",fontSize:"16px",lineHeight:"2rem",alignItems:"center"}}><AddIcon sx={{width:"1.5%"}}/>Language</Typography> */}
            <Box sx={{mt:5}}>
            <Typography component="p" sx={{fontSize:"20px",fontWeight:600}}>Expertise</Typography>
            <Typography component="p" sx={{lineHeight:"2rem",display:"flex",alignItems:"center"}}><Box component="img" src={Shape} sx={{width:{xs:"8%",md:"2%"},mx:1}}/>Cleaning</Typography>
            <Typography component="p" sx={{lineHeight:"2rem",display:"flex",alignItems:"center"}}><Box component="img" src={Shape} sx={{width:{xs:"8%",md:"2%"},mx:1}}/>Cooking</Typography>
            <Typography component="p" sx={{lineHeight:"2rem",display:"flex",alignItems:"center"}}><Box component="img" src={Shape} sx={{width:{xs:"8%",md:"2%"},mx:1}}/>Laundry</Typography>
            </Box>
            <Box sx={{mt:3}}>
            <Typography component="p" sx={{fontSize:"20px",fontWeight:600}}>Location</Typography>
            <Typography component="p" sx={{lineHeight:"2rem",display:"flex",alignItems:"center"}}><Box component="img" src={Shape} sx={{width:{xs:"8%",md:"2%"},mx:1}}/>Riyadh,Saudi</Typography>
            </Box>



    </Box>
      </CustomTabPanel>
      {/* ----------------------------------------Reviews--------------------------------- */}
      <CustomTabPanel value={value} index={1}>
      <Box>
      <Typography component="p" sx={{fontSize:"24px",fontWeight:600}}>About Juliana</Typography>
      <Typography component="p" sx={{lineHeight:"2rem"}}>Lorem ipsum dolor amit consect Lorem ipsum dolor amit consecteur, Lorem ipsum dolor amit consecteur</Typography>
      <Box sx={{mt:3}}>
      <Accordion sx={{width:"60%"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{color:"blue"}}
        >
        Application Details
        </AccordionSummary>
        <AccordionDetails>
          <Table/>
        </AccordionDetails>
      </Accordion>
    </Box>
      <Box sx={{mt:3}}>
      <Accordion sx={{width:"60%"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{color:"blue"}}
        >
        Personal Information
        </AccordionSummary>
        <AccordionDetails>
          <Personal/>
        </AccordionDetails>
      </Accordion>
    </Box>
      <Box sx={{mt:3}}>
      <Accordion sx={{width:"60%"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{color:"blue"}}
        >
        Passport & VISA
        </AccordionSummary>
        <AccordionDetails>
          <Passport/>
        </AccordionDetails>
      </Accordion>
    </Box>
      <Box sx={{mt:3}}>
      <Accordion sx={{width:"60%"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{color:"blue"}}
        >
        Work Experience
        </AccordionSummary>
        <AccordionDetails>
          <Experience/>
        </AccordionDetails>
      </Accordion>
    </Box>
      <Box sx={{mt:3}}>
      <Accordion sx={{width:"60%"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{color:"blue"}}
        >
        Language
        </AccordionSummary>
        <AccordionDetails>
          <Language/>
        </AccordionDetails>
      </Accordion>
    </Box>

            
            {/* <Typography component="p" sx={{color:"blue",display:"flex",fontSize:"16px",lineHeight:"2rem",alignItems:"center"}}><AddIcon sx={{width:"1.5%"}}/> Application Details</Typography>
            <Typography component="p" sx={{color:"blue",display:"flex",fontSize:"16px",lineHeight:"2rem",alignItems:"center"}}><AddIcon sx={{width:"1.5%"}}/>Personal Information</Typography>
            <Typography component="p" sx={{color:"blue",display:"flex",fontSize:"16px",lineHeight:"2rem",alignItems:"center"}}><AddIcon sx={{width:"1.5%"}}/>Passport & VISA</Typography>
            <Typography component="p" sx={{color:"blue",display:"flex",fontSize:"16px",lineHeight:"2rem",alignItems:"center"}}><AddIcon sx={{width:"1.5%"}}/>Work Experience</Typography>
            <Typography component="p" sx={{color:"blue",display:"flex",fontSize:"16px",lineHeight:"2rem",alignItems:"center"}}><AddIcon sx={{width:"1.5%"}}/>Language</Typography> */}
            <Box sx={{mt:5}}>
            <Typography component="p" sx={{fontSize:"20px",fontWeight:600}} >Expertise</Typography>
            <Typography component="p" sx={{lineHeight:"2rem"}}>Cleaning</Typography>
            <Typography component="p" sx={{lineHeight:"2rem"}}>Cooking</Typography>
            <Typography component="p" sx={{lineHeight:"2rem"}}>Laundry</Typography>
            </Box>
            <Box sx={{mt:3}}>
            <Typography component="p" sx={{fontSize:"20px",fontWeight:600}} >Location</Typography>
            <Typography component="p" sx={{lineHeight:"2rem"}}>Riyadh,Saudi</Typography>
            </Box>



    </Box>
      </CustomTabPanel>
      
    </Box>
  );
}
