import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Logo from '../assets/images/Header_logo.png'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import Gpay from '../assets/images/GPay.png'
import Visa from '../assets/images/Visa.png'
import Map from '../assets/images/Map.png'
import Grid from '@mui/material/Grid'
import { useNavigate } from 'react-router-dom';

function Footer() {
     const navigate = useNavigate()
  return (
    <Box>
        <Box sx={{backgroundColor:"#2D4EB7",py:10}}>
            <Box sx={{textAlign:{xs:"center",md:""}}}>
                <Grid container sx={{width:"80%",mx:"auto",borderTop:3,borderColor:"white",borderBottom:1,borderBottomColor:"#FFFFFF"}}>
                    <Grid item xs={12} md={2} sx={{mt:5}}>
                     <Box component="img" src={Logo} sx={{width:"47%",borderColor:"white"}}/>
                    </Grid>
           
                <Grid item xs={12} md={6} sx={{mt:5}}>
                <Box>
                <Typography component="p" sx={{color:"white",fontSize:"12px",display:{xs:"flex"},alignItems:"center"}}><LocationOnIcon sx={{mr:1}}/> 345 Faulconer Drive, Suite 4 • Charlottesville, CA, 12345</Typography>
                <Typography component="p" sx={{color:"white",fontSize:"12px",mt:2,display:"flex",alignItems:"center"}}><CallIcon sx={{mr:1}}/> (123) 456-7890</Typography>
                <Typography component="p" sx={{color:"white",fontSize:"14px",mt:2,display:"flex",alignItems:"center"}}> Pay with <Box component="img" src={Gpay} sx={{mx:1,width:"6%",borderRadius:1,backgroundColor:"white",p:1}}/><Box component="img" src={Visa} sx={{mx:1,width:"7.5%",borderRadius:1,backgroundColor:"white",p:1}}/>  </Typography>
            
           </Box>
                </Grid>
           <Grid item xs={12} md={4} sx={{mt:5,mb:3}}>
           <Box>
                <Box component="img" src={Map} sx={{width:"75%"}}/>
           </Box>
           </Grid>
        </Grid>
           </Box>
           <Box sx={{display:"flex",color:"white",width:"83%",mx:"auto",p:3,textTransform:"uppercase"}}>
                <Typography component="p" onClick={()=>navigate("/tadbeer")} sx={{cursor:"pointer",mx:2,fontSize:"12px"}}>About Us</Typography>
                <Typography component="p" onClick={()=>navigate("/intro")} sx={{cursor:"pointer",mx:2,fontSize:"12px"}}>VAT</Typography>
                <Typography component="p" onClick={()=>navigate("/tadbeer/contact")} sx={{cursor:"pointer",mx:2,fontSize:"12px"}}>Contact Us</Typography>
                <Typography component="p" onClick={()=>navigate("/tadbeer")} sx={{cursor:"pointer",mx:2,fontSize:"12px"}}>Help</Typography>
                <Typography component="p" onClick={()=>navigate("/tadbeer/categories")} sx={{cursor:"pointer",mx:2,fontSize:"12px"}}>Maid</Typography>
                <Typography component="p" onClick={()=>navigate("/tadbeer")} sx={{cursor:"pointer",mx:2,fontSize:"12px"}}>Pricing</Typography>
           </Box>
        </Box>
    </Box>
  )
}

export default Footer