import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Logo from '../assets/images/Logo.png';
import {useNavigate} from 'react-router-dom'
function ResponsiveAppBar() {
  const navigate = useNavigate()
const style={
fontWeight: '600',
fontSize:"12px",
capitalize: 'bold',
textTransform: 'uppercase',
letterSpacing:"1px",
color:"white",
cursor:"pointer"

}
  return (
    <Box sx={{mt:1,display:"flex",justifyContent:"space-around",alignItems:"center"}}>
        <Typography sx={style} >Industries</Typography>
        <Typography sx={style} onClick={()=>navigate("/")}>service</Typography>
        <Box component={"img"} onClick={()=>navigate("/intro")} sx={{width:"5%",display:"inline"}} src={Logo}/>
        <Typography sx={style} onClick={()=>navigate("/about")}>About us</Typography>
        <Typography sx={style} onClick={()=>navigate("/contact")}>contact</Typography>
    </Box>
  );
}
export default ResponsiveAppBar;