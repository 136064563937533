import { useState, lazy, Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import Box from '@mui/material/Box';
import { LinearProgress } from '@mui/material';
import PrivateRoute from "./Tadbeer/srcdddd/PrivateRoute"; // Assuming PrivateRoute is in a proper location (./Tadbeer)

// Import components directly from their respective files:
import Home from './Homepage/home';
import Intro from './Introduction/intro';
import Contact from './Contact/contact';
import Footer from './Footer/footer';
import About from './About/about';
import Service from './Services/service';
import Industries from './Industries/industries';

// Tadbeer components (assuming they are in the ./Tadbeer directory):
import CategoriesTadbeer from "./Tadbeer/srcdddd/Categories/categories";
import ContactTadbeer from "./Tadbeer/srcdddd/Contact/contact";
import HomeTadbeer from "./Tadbeer/srcdddd/Home/home";

const routes = [ // Use 'routes' for better naming convention
  { path: "/", element: <Home/> },
  { path: "contact", element: <Contact /> },
  { path: "about", element: <About /> },
  { path: "service", element: <Service /> },
  { path: "industries", element: <Industries /> },
  { path: "intro", element: <Intro /> },
  {
    path: "tadbeer",
    element: <PrivateRoute><HomeTadbeer/></PrivateRoute>, // Use <PrivateRoute> component
  },
  {
    path: "tadbeer/categories",
    element: <PrivateRoute><CategoriesTadbeer/></PrivateRoute>,
  },
  {
    path: "tadbeer/contact",
    element: <PrivateRoute><ContactTadbeer/></PrivateRoute>,
  },
];

const router = createBrowserRouter(routes);

function App() {
  return (
    <>
      <RouterProvider router={router} />


    </>
  );
}

export default App;
