import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Date of Birth',"25/05/1999", "تاريخ الميلاد"),
  createData('Place of Birth', "LEBAK", "مكان الميلاد"),
  createData('Religion', "MUSLIM", "ديانة"),
  createData('Marital Status', "MARRIED", "الحالة الاجتماعية"),
  createData('No. of Children', "1", "عند الأطفال"),
  createData('Sex', "FEMALE", "الجنس"),
  createData('Age', "26 Years", "العمر"),
  createData('Height', "156 CM", "الأطول"),
  createData('Weight', "55 KG", "الوزن"),
  createData('Complextion', "MARRIED", "الون البشرة"),
  createData('Qualification', "JUNIOR HIGH SCHOOL GRAD", "المؤهل العلمي"),
];

export default function DenseTable() {
  return (
    <TableContainer component={Paper} sx={{}}>
      <Table sx={{ minWidth: 50 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell sx={{fontSize:"16px",fontWeight:600}}>Nationality</TableCell>
            <TableCell sx={{textAlign:"center"}} align="right">INDONESIA</TableCell>
            <TableCell sx={{}} align="right">الجنسية</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{fontSize:"16px",fontWeight:600}}>
                {row.name}
              </TableCell>
              <TableCell align="right" sx={{textAlign:"center"}}>{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}