import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid'
import Similar from '../assets/images/Similar People.png'
import Profile_button from '../assets/images/profile_button.png'
import Flag from '../assets/images/Flag.png'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import Women from '../assets/images/Women.png'
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import Swiper from '../Categories/swiper'


function Categories() {
    const [age, setAge] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  document.body.style.backgroundColor ="white"

  return (
    <Box sx={{width:"95%",mx:"auto"}}>
        <Box sx={{display:"flex",alignItems:"center"}}>
        <Box>
            <Typography component="p" sx={{fontSize:{xs:"22px",md:"30px"},fontWeight:600,display:"flex"}}>Need a Verified Maid? <Typography component="p" sx={{mx:1,color:"red",fontSize:{xs:"22px",md:"30px"},fontWeight:600}}> Explore</Typography></Typography>
            <Typography component="p" sx={{color:"red",fontSize:{xs:"22px",md:"30px"},fontWeight:600}}>our Maids in Service</Typography>
            <Typography component="p" sx={{fontSize:{xs:"16px",md:"22px"},fontWeight:600,mt:2}}>A One Stop Solution to Recruit</Typography>

            <Box sx={{mt:3,display:"flex",justifyContent:"space-between",border:1,borderRadius:2,borderColor:"#00000040",width:{xs:"90%",md:"100%"}}}>
                <Typography component="p" sx={{width:"60%",p:1,display:"flex"}}>Expertise<KeyboardArrowDownIcon sx={{mx:1}}/>Location<KeyboardArrowDownIcon sx={{mx:1}}/>Keywords<KeyboardArrowDownIcon sx={{mx:1}}/></Typography>
                <Box sx={{border:1,borderRadius:"0px 5px 5px 0px",width:"8%",backgroundColor:"#2D4EB7",color:"white",p:0.8,textAlign:"center"}}><SearchIcon/></Box>
            </Box>

            {/* <Box sx={{display:"flex",border:1}}>
                <Box>
                <FormControl sx={{ m: 1, minWidth: 120,mt:"0px",mb:"0px"}}>
        <InputLabel id="demo-controlled-open-select-label" sx={{color:"black"}}>Expertise</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={age}
          label="Age"
          onChange={handleChange}
          // Apply border removal style using sx prop
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>None</MenuItem>
          <MenuItem value={20}>None</MenuItem>
          <MenuItem value={30}>None</MenuItem>
        </Select>
      </FormControl>
                </Box>
                <Box>
                <FormControl sx={{ m: 1, minWidth: 120}}>
        <InputLabel id="demo-controlled-open-select-label" sx={{color:"black"}}>Location</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={age}
          label="Age"
          onChange={handleChange}
          // Apply border removal style using sx prop
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>None</MenuItem>
          <MenuItem value={20}>None</MenuItem>
          <MenuItem value={30}>None</MenuItem>
        </Select>
      </FormControl>
                </Box>
                <Box>
                <FormControl sx={{ m: 1, minWidth: 120}}>
        <InputLabel id="demo-controlled-open-select-label" sx={{color:"black"}}>Keywords</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={age}
          label="Age"
          onChange={handleChange}
          // Apply border removal style using sx prop
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>None</MenuItem>
          <MenuItem value={20}>None</MenuItem>
          <MenuItem value={30}>None</MenuItem>
        </Select>
      </FormControl>
                </Box>
            </Box> */}
       
            <Stack sx={{mt:3}}>
                <Button variant="contained" sx={{width:{xs:"49%",md:"75%"},fontSize:{xs:"10px",md:"18px"},textTransform:"capitalize",py:1.5,backgroundColor:"#2D4EB7"}}>Search All Maids / Nannies</Button>
            </Stack>
        </Box>
        <Box component="img" src={Women} sx={{display:{xs:"none",md:"flex"}}}/>
        </Box>

        

        <Box sx={{display:"flex",justifyContent:"space-between",py:3}}>
            <Typography component="p" sx={{fontSize:{xs:"22px",md:"30px"},fontWeight:600}}>Categories</Typography>
            <Typography component="p" sx={{fontSize:{xs:"18px",md:"28px"},color:"grey"}}>View All</Typography>
        </Box>

        

        <Box>
            <Swiper/>
        </Box>

        <Box sx={{display:"flex",justifyContent:"space-between",alignTracks:"center",py:5}}>
            <Typography component="p" sx={{fontSize:{xs:"22px",md:"30px"},fontWeight:600}}>Popular Services</Typography>
            <Typography component="p" sx={{fontSize:{xs:"18px",md:"28px"},color:"grey"}}>View All</Typography>
        </Box>
        <Box>
        <Stack direction="row" spacing={{xs:3,md:4}} >
      <Chip label="Cleaning" variant="outlined" sx={{fontSize:{xs:"18px",md:"22px"},py:{xs:1,md:3},borderRadius:"50px 50px 50px 50px",border:2,color:"white",backgroundColor:"blue",borderColor:"blue"}} />
      <Chip label="Repairing" variant="outlined" sx={{fontSize:{xs:"18px",md:"22px"},py:{xs:1,md:3},borderRadius:"50px 50px 50px 50px",border:2,color:"blue",backgroundColor:"white",borderColor:"blue"}} />
      <Chip label="Laundry" variant="outlined" sx={{fontSize:{xs:"18px",md:"22px"},py:{xs:1,md:3},borderRadius:"50px 50px 50px 50px",border:2,color:"blue",backgroundColor:"white",borderColor:"blue"}} />
      <Chip label="Baby Sitter" variant="outlined" sx={{fontSize:"22px",py:3,borderRadius:"50px 50px 50px 50px",border:2,color:"blue",backgroundColor:"white",borderColor:"blue",display:{xs:"none",md:"flex"}}} />
      <Chip label="Repairing" variant="outlined" sx={{fontSize:"22px",py:3,borderRadius:"50px 50px 50px 50px",border:2,color:"blue",backgroundColor:"white",borderColor:"blue",display:{xs:"none",md:"flex"}}} />
      <Chip label="Laundry" variant="outlined" sx={{fontSize:"22px",py:3,borderRadius:"50px 50px 50px 50px",border:2,color:"blue",backgroundColor:"white",borderColor:"blue",display:{xs:"none",md:"flex"}}} />
      <Chip label="Baby Sitter" variant="outlined" sx={{fontSize:"22px",py:3,borderRadius:"50px 50px 50px 50px",border:2,color:"blue",backgroundColor:"white",borderColor:"blue",display:{xs:"none",md:"flex"}}} />
      <Chip label="Cleaning" variant="outlined" sx={{fontSize:"22px",py:3,borderRadius:"50px 50px 50px 50px",border:2,color:"blue",backgroundColor:"white",borderColor:"blue",display:{xs:"none",md:"flex"}}} />
     </Stack>
        </Box>

        <Box sx={{py:5}}>
        <Grid container >
        {[0,1,2,3,4,5].map((item,index)=>{
            return <Grid item xs={12} md={6} key={index}>
<Box sx={{border:2,py:2,mx:2,borderRadius:3,borderColor:"#0000000D",mb:2}}>
            <Box sx={{mx:2}}>
                <Grid container>
                <Grid item xs={4} md={4}>
                        <Box sx={{position:"relative"}}>
                        <Box component="img" src={Similar} sx={{width:"80%"}}/>
                        <Box component="img" src={Profile_button} sx={{width:"14%",top:"76%",left:"2%",zIndex:1,position:"absolute"}}/>
                        </Box>
                    </Grid>
            <Grid item xs={6} md={6}>
            <Box>
                <Typography componen="p" sx={{fontSize:"18px",border:1,width:"150px",color:"blue",backgroundColor:"#DFE1EF80",borderColor:"#DFE1EF80",borderRadius:1}}>Juliana</Typography>
                <Typography component="p" sx={{display:"flex",flexDirection:"row",alignItems:"center",fontSize:{xs:"13px",md:"18px"}}}>Nationality :  <Typography component="img" src={Flag} sx={{width:"7%",mx:1}}></Typography> Uganda</Typography>
                <Typography componen="p" sx={{fontWeight:600,fontSize:{xs:"17px",md:"20px"}}}>Housemaid</Typography>
                <Typography componen="p" sx={{color:"blue",fontSize:"20px",fontWeight:"600"}}>Salary: 1300</Typography>
                <Typography componen="p" sx={{fontSize:{xs:"15px",md:"17px"},display:"flex",flexDirection:"row",alignItems:"center"}}>4.8 <Typography component="p" sx={{fontSize:{xs:"0.6rem",md:"1rem"},mx:1,color:"#737373E5"}}>| 4 Years of Experience</Typography></Typography>

            </Box>
            </Grid>
            <Grid item xs={2} md={2} >
                <Box sx={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                    <FavoriteBorderIcon sx={{fontSize:{xs:"28px",md:"34px"},color:"#a1005e"}}/>
                    <ShortcutIcon sx={{fontSize:{xs:"28px",md:"34px"},color:"grey",mt:4}}/>
                 </Box>
            </Grid>
        </Grid>
        </Box>
    </Box>
            </Grid>
        })}
    
    </Grid>
        </Box>

    </Box>
  )
}

export default Categories