import { Box } from '@mui/material'
import React from 'react'
import Typography from '@mui/material/Typography'
import Profile from '../assets/images/Profile.png'
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function Contact() {
  document.body.style.backgroundColor ="white"
  return (
    <Box sx={{py:10,backgroundColor:"white"}}>

        <Box sx={{boxShadow: 9,py:3,borderRadius:2,borderColor:"#dee0d9 !important",border:1,width:{xs:"70%",md:"40%"},mx:"auto",height:{xs:"86vw",md:"33vw"},textAlign:"center"}}>
            <Typography component="p" sx={{fontSize:"28px",fontWeight:800,mt:3}}>Contact</Typography>
            <Box component="img" src={Profile} sx={{width:"28%",alignItems:"center",mt:2}}/>
            <Typography component="p" sx={{fontSize:"22px",fontWeight:400,py:1}}>Juliana</Typography>
         
            <Box sx={{}}>
            <Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <CallIcon sx={{color:"blue"}}/>
                <Box component="p">(123) 456-7890</Box>
                </Box>
            <Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <WhatsAppIcon sx={{color:"green"}}/>
                <Box component="p">(123) 456-7890</Box>
                </Box>
                </Box>
       
        </Box>


    </Box>
  )
}

export default Contact