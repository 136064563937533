import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './swiper.css';

import IML from '../assets/images/Babysitter.png'

// import required modules
import { Pagination, Navigation } from 'swiper/modules';
import { Box, Typography } from '@mui/material';

export default function App() {
  return (
    <Box sx={{my:{xs:1,md:4}}}>
      <Swiper
      
        navigation={true}
        modules={[Pagination, Navigation]}
        slidesPerView={7}
        className="mySwiper"
        sx={{display:{xs:"none",md:"block"}}}
      >
        {[0,1,2,3,4,5,6,7].map((item,index)=>{
            return <SwiperSlide key={index}>
                <Box component={"img"} src={IML} sx={{width:"20px"}}/>
                <Typography sx={{fontSize:"19px",fontWeight:"550",mt:2}}>Cooking</Typography>
                </SwiperSlide> 
        })}
        
      </Swiper>
      
    </Box>
  );
}


// import SwiperCore, { Pagination, Navigation } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css'; // Import Swiper styles
// import 'swiper/css/navigation'; // Import Navigation module styles
// import 'swiper/css/pagination'; // Import Pagination module styles

// SwiperCore.use([Pagination, Navigation]);

// const MySwiper = () => {
//   const [slidesPerViewMobile, setSlidesPerViewMobile] = React.useState(2); // State for mobile view slidesPerView

//   React.useEffect(() => {
//     const handleResize = () => {
//       const windowWidth = window.innerWidth;
//       if (windowWidth < 768) {
//         setSlidesPerViewMobile(2); // Set slidesPerView to 2 for mobile
//       } else {
//         setSlidesPerViewMobile(7); // Set slidesPerView to 7 for larger screens
//       }
//     };

//     window.addEventListener('resize', handleResize);

//     // Cleanup function to remove event listener on component unmount
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   return (
//     <Swiper
//       navigation={true}
//       modules={[Pagination, Navigation]}
//       slidesPerView={slidesPerViewMobile}
//       // ... other Swiper props (className, etc.)
//       sx={{ display: { xs: "block", md: "block" } }} // Consistent display for all viewports
//     >
//       {[0, 1, 2, 3, 4, 5, 6, 7].map((item, index) => (
//         <SwiperSlide key={index}>
//           <Box component="img" src={IML} sx={{ width: "20px" }} />
//           <Typography sx={{ fontSize: "19px", fontWeight: "550", mt: 2 }}>
//             Cooking
//           </Typography>
//         </SwiperSlide>
//       ))}
//     </Swiper>
//   );
// };