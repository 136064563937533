import React, { useEffect,useState } from "react"
import {Box,Typography} from "@mui/material"
import Home from '../assets/images/desert-landscape-background-video-conferencing_23-2148652924 1.png'
// import Home2 from '../assets/images/cutpiece_1.png'
// import Home3 from '../assets/images/cutpiece_2.png'
import Home2 from '../assets/images/dubaiFirst1.png'
import Home3 from '../assets/images/dubaisecond.png'
import mobile1  from '../assets/images/mobile1.png'
import mobile2  from '../assets/images/mobile2.png' 
import './home.css'
import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from '@gsap/react';
import {useNavigate} from  "react-router-dom"



gsap.registerPlugin(useGSAP);


const Hero =()=>{
const navigate = useNavigate()
 const container = useRef();
 let tl = useRef()


 const toggleTimeline = () => {
 tl.current.reversed(!tl.current.reversed());
 setTimeout(()=>navigate('/intro'),1200)
 };


const [duration,setDuration] = useState({
  dur1:3,
  dur2:3,
})


  useGSAP(
    () => {
      const boxes = gsap.utils.toArray('.box');
      tl.current = gsap
        .timeline()
        .to(boxes[0], { overflowX:"hidden",left: -900,display:"none", duration: 3 })
        .to(boxes[1], {overflowX:"hidden", left: "100%",display:"none",duration: 1

         },"=")
        .reverse();
    },
    { scope: container }
    
    
  );




  const handleResize = () => {
    const windowWidth = window.innerWidth;
    const isDesktop = windowWidth >= 768;

    if (isDesktop) {
      setDuration({ dur1: 2, dur2:2 }); // Desktop durations
    } else {
      setDuration({ dur1: 6, dur2: 1 }); // Mobile durations
    }
  };

  
  useEffect(()=>{
    handleResize()
  },[])

  // Event listener for resize events
  window.addEventListener("resize",handleResize );

  document.body.style.backgroundColor ="black"


    document.title="Home"
    return(
      <>
      
      <Box onClick={toggleTimeline} sx={{postition:"relative",maxHeight:"100vh",maxWidth:{sm:"100vw",md:"100vw",lg:"100vw",xl:"100vw"},overflowX:"hidden"}} ref={container} >
        <Box component="img" className="home" src={Home} sx={{maxWidth:"100vw",overflowX:"hidden",objectFit:"cover",height:"100vh",width:"99.2%",top:0,bottom:0,position: 'absolute',}}/>
       
       {/*Desktop*/}
        <Box component="img"  sx={{maxHeight:"100vh",height:{xs:"100vh",md:"100vh"}, position: 'absolute',top:0,bottom:0}}   className="box piece1"  src={Home2}/>
        <Box component="img"  sx={{maxHeight:{xs:"100vh",md:"100vh"},position:'absolute',top:0,right:0,bottom:0}}   className="box piece2" src={Home3}/>



        <Box  component="p" sx={{mx:"auto",color:"black ",cursor:"pointer",position: 'absolute'}} className="piece3">
          <Typography component={"p"} sx={{display:"block",textAlign:"center",fontSize:{xs:"16px",md:"28px"},fontFamily:"Montserrat"}}> 
          WELCOME'S TO</Typography> 
          <Typography component={"span"} sx={{fontSize:{xs:"16px",md:"30px"},fontWeight:"bold",fontFamily:"Montserrat", wordSpacing:{md:"10px"},letterSpacing:{md:"2px"},ml:2}}>VAPPAS ADETIVE TECHNOLOGY</Typography>  </Box>
         </Box>

    




         </>
  )


  }
  export default Hero;


